export const locale = {
    lang: 'en',
    data: {
        SAMPLE: {
            AWESOME: 'Create Awesome 🙌'
        },
        "Navbar": {
            "downloadApp": "Download the App",
            "loginDashboard": "Login to Dashboard"
        },
        "Hero": {
            "welcomeMessage": "Welcome to",
            "brandName": "IAM IN Education",
            "empoweringEducation": "Empowering education through technology",
            "downloadApp": "Download Our App"
        },
        "Home": {
            "totalInstructors": "Total Instructors",
            "InstructorsDesc": "Our qualified instructors",
            "totalStudents": "Total Students",
            "StudentsDesc": "Join thousands of learners",
            "totalCourses": "Total Courses",
            "CoursesDesc": "Explore our extensive courses",
            "totalLives": "Total Live Sessions",
            "LivesDesc": "Interact in real-time with our experts"
        },
        "Features": {
            "rangeOfCourses": "Wide Range of Courses",
            "rangeOfCoursesDesc": "Access a variety of courses across different subjects and levels.",
            "expertInstructors": "Expert Instructors",
            "expertInstructorsDesc": "Learn from industry experts and experienced educators.",
            "learnAnytime": "Learn Anytime, Anywhere",
            "learnAnytimeDesc": "Our mobile app allows you to learn on the go, anytime, anywhere."
        },
        "DownloadSection": {
            "title": "Download Our App",
            "description": "Available on iOS and Android",
            "appStore": "Download on the App Store",
            "googlePlay": "Get it on Google Play"
        },
        "ContactUs": {
            "title": "Contact Us",
            "description": "We would love to hear from you!",
            "nameLabel": "Name",
            "nameError": "Name is required.",
            "emailLabel": "Email",
            "emailError": "A valid email is required.",
            "messageLabel": "Message",
            "messageError": "Message is required.",
            "sendMessage": "Send Message"
        },
        "Footer": {
            "copyright": "All rights reserved.",
            "termsConditions": "Terms and Conditions",
            "privacyPolicy": "Privacy Policy",
            "followUs": "Follow us on"
        },
        "title1": "Discover New Horizons",
        "subtitle1": "Your journey to knowledge starts here",
        "title2": "Unleash Your Potential",
        "subtitle2": "Unlock endless opportunities with our courses",
        "title3": "Join the Community",
        "subtitle3": "Connect with experts and fellow learners"

    }
};
