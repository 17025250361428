<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light ml-4 mr-4" [dir]="isArabic ? 'rtl' : 'ltr'">
    <img alt="brand-logo" class="m-2" height="50" src="../../../../../assets/images/logo/logo.png" />

    <button aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
        data-target="#navbarNav" data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" ngbDropdown>
                <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
                    <i
                        class=" m-2 flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i><span
                        class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span>
                </a>
                <div aria-labelledby="dropdown-flag" ngbDropdownMenu>
                    <a (click)="setLanguage(lang)" *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem>
                        <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i>
                        {{ languageOptions[lang].title }}
                    </a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#download"><span class="p-2">{{ 'Navbar.downloadApp' | translate }}</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link btn btn-primary text-white" href="pages/auth/login">{{ 'Navbar.loginDashboard' |
                    translate }}</a>
            </li>
        </ul>
    </div>
</nav>

<!-- Hero Section -->
<div class="hero-section text-center text-white" [dir]="isArabic ? 'rtl' : 'ltr'">
    <div class="col-md-12 col-12">
        <h1 class="display-4 text-white">{{ 'Hero.welcomeMessage' | translate }} <strong>{{ 'Hero.brandName' | translate
                }}</strong></h1>
        <p class="lead">{{ 'Hero.empoweringEducation' | translate }}</p>
        <a class="btn btn-primary btn-lg" href="#download">{{ 'Hero.downloadApp' | translate }}</a>
    </div>
</div>


<!-- Stats Section -->
<section class="stats-container" [dir]="isArabic ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3 mt-25 mt-lg-0">
                <div class="stats-item d-flex flex-column align-items-center text-center py-30 px-5 w-100">
                    <div class="stat-icon-box teacher">
                        <!-- SVG Icon Here -->
                        <img class="p-2" alt="brand-logo"
                            src="../../../assets/images/illustration/chalkboard-teacher-solid.svg" />
                    </div>
                    <strong class="stat-number mt-4">{{ homePageData?.totalInstructors }}</strong>
                    <h4 class="stat-title">{{ 'Home.totalInstructors' | translate }}</h4>
                    <p class="stat-desc mt-10">{{ 'Home.InstructorsDesc' | translate }}</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mt-25 mt-lg-0">
                <div class="stats-item d-flex flex-column align-items-center text-center py-30 px-5 w-100">
                    <div class="stat-icon-box teacher">
                        <!-- SVG Icon Here -->
                        <img class="p-2" alt="brand-logo"
                            src="../../../assets/images/illustration/user-graduate-solid.svg" />
                    </div>
                    <strong class="stat-number mt-4">{{ homePageData?.totalStudents }}</strong>
                    <h4 class="stat-title">{{ 'Home.totalStudents' | translate }}</h4>
                    <p class="stat-desc mt-10">{{ 'Home.StudentsDesc' | translate }}</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 mt-25 mt-lg-0">
                <div class="stats-item d-flex flex-column align-items-center text-center py-30 px-5 w-100">
                    <div class="stat-icon-box teacher">
                        <!-- SVG Icon Here -->
                        <img class="p-2" alt="brand-logo" src="../../../assets/images/illustration/video-solid.svg" />
                    </div>
                    <strong class="stat-number mt-4">{{ homePageData?.totalLives }}</strong>
                    <h4 class="stat-title">{{ 'Home.totalLives' | translate }}</h4>
                    <p class="stat-desc mt-10">{{ 'Home.LivesDesc' | translate }}</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 mt-25 mt-lg-0">
                <div class="stats-item d-flex flex-column align-items-center text-center py-30 px-5 w-100">
                    <div class="stat-icon-box teacher">
                        <!-- SVG Icon Here -->
                        <img class="p-2" alt="brand-logo"
                            src="../../../assets/images/illustration/photo-video-solid.svg" />

                    </div>
                    <strong class="stat-number mt-4">{{ homePageData?.totalCourses }}</strong>
                    <h4 class="stat-title">{{ 'Home.totalCourses' | translate }}</h4>
                    <p class="stat-desc mt-10">{{ 'Home.CoursesDesc' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Features Section -->
<section class="features-section py-5" id="features" [dir]="isArabic ? 'rtl' : 'ltr'">
    <div class="col-md-12 col-12">
        <div class="row">
            <div class="col-md-4 text-center">
                <img alt="brand-logo" class="p-4" height="150"
                    src="../../../assets/images/illustration/marketing.svg" />
                <h3>{{ 'Features.rangeOfCourses' | translate }}</h3>
                <p>{{ 'Features.rangeOfCoursesDesc' | translate }}</p>
            </div>
            <div class="col-md-4 text-center">
                <img alt="brand-logo" class="p-4" height="150"
                    src="../../../assets/images/illustration/faq-illustrations.svg" />
                <h3>{{ 'Features.expertInstructors' | translate }}</h3>
                <p>{{ 'Features.expertInstructorsDesc' | translate }}</p>
            </div>
            <div class="col-md-4 text-center">
                <img alt="brand-logo" class="p-4" height="150"
                    src="../../../assets/images/illustration/personalization.svg" />
                <h3>{{ 'Features.learnAnytime' | translate }}</h3>
                <p>{{ 'Features.learnAnytimeDesc' | translate }}</p>
            </div>
        </div>
    </div>
</section>

<!-- Slider section -->
<section class="features-section py-5" id="features">
    <!-- Carousel container with dynamic direction based on language -->
    <div (mouseenter)="pause()" (mouseleave)="play()" class="carousel slide">
        <div class="carousel-inner">
            <!-- Iterate through slides dynamically and mark the active one -->
            <div *ngFor="let slide of slides; let i = index" [class.active]="i === currentSlide" class="carousel-item">
                <img [src]="slide.image" class="d-block w-100" alt="Slide image">
                <div class="carousel-caption d-none d-md-block">
                    <h5 class="carousel-title">{{ slide.title }}</h5>
                    <p class="carousel-subtitle">{{ slide.subtitle }}</p>
                </div>
            </div>
        </div>

        <!-- Carousel controls -->
        <a (click)="isArabic ? prev() : next()" class="carousel-control-prev" role="button">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a (click)="isArabic ? next() : prev()" class="carousel-control-next" role="button">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</section>



<!-- Download Section -->
<section class="download-section py-5 bg-light" id="download" [dir]="isArabic ? 'rtl' : 'ltr'">
    <div class="col-md-12 col-12 text-center">
        <h2>{{ 'DownloadSection.title' | translate }}</h2>
        <p class="lead">{{ 'DownloadSection.description' | translate }}</p>
        <div class="d-flex justify-content-center">
            <a class="btn btn-outline-dark btn-lg mx-2" href="#"><i class="fab fa-apple m-2"></i> {{
                'DownloadSection.appStore' | translate }}</a>
            <a class="btn btn-outline-dark btn-lg mx-2" href="#"><i class="fab fa-google-play m-2"></i> {{
                'DownloadSection.googlePlay' | translate }}</a>
        </div>
    </div>
</section>


<!-- Contact Us Section -->
<section class="contact-us-section py-5" id="contact-us" [dir]="isArabic ? 'rtl' : 'ltr'">
    <div class="col-md-12 col-12">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <h2 class="text-center">{{ 'ContactUs.title' | translate }}</h2>
                <p class="text-center">{{ 'ContactUs.description' | translate }}</p>
                <form (ngSubmit)="onSubmit()" [formGroup]="contactForm">
                    <div class="form-group">
                        <label for="name">{{ 'ContactUs.nameLabel' | translate }}</label>
                        <input class="form-control" formControlName="name" id="name" type="text" />
                        <div *ngIf="contactForm.get('name').invalid && contactForm.get('name').touched"
                            class="text-danger">
                            {{ 'ContactUs.nameError' | translate }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">{{ 'ContactUs.emailLabel' | translate }}</label>
                        <input class="form-control" formControlName="email" id="email" type="email" />
                        <div *ngIf="contactForm.get('email').invalid && contactForm.get('email').touched"
                            class="text-danger">
                            {{ 'ContactUs.emailError' | translate }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="message">{{ 'ContactUs.messageLabel' | translate }}</label>
                        <textarea class="form-control" formControlName="message" id="message" rows="5"></textarea>
                        <div *ngIf="contactForm.get('message').invalid && contactForm.get('message').touched"
                            class="text-danger">
                            {{ 'ContactUs.messageError' | translate }}
                        </div>
                    </div>
                    <button [disabled]="contactForm.invalid" class="btn btn-primary btn-block" type="submit">
                        {{ 'ContactUs.sendMessage' | translate }}
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>


<!-- Footer Section -->
<footer class="footer-section py-4" [dir]="isArabic ? 'rtl' : 'ltr'">
    <div class="col-md-12 col-12 text-center">
        <p class="mb-0">© 2024 IAM IN. {{ 'Footer.copyright' | translate }}</p>
        <p class="mb-0">
            <a class="text-dark" href="/terms-and-conditions">{{ 'Footer.termsConditions' | translate }}</a> |
            <a class="text-dark mr-4" href="/privacy-policy">{{ 'Footer.privacyPolicy' | translate }}</a>
        </p>
        <p class="mb-0">
            {{ 'Footer.followUs' | translate }}
            <a class="text-dark" href="#"><i class="fab fa-facebook-f mx-2"></i></a>
            <a class="text-dark" href="#"><i class="fab fa-twitter mx-2"></i></a>
            <a class="text-dark" href="#"><i class="fab fa-instagram mx-2"></i></a>
        </p>
    </div>
</footer>