export const locale = {
    lang: 'ar',
    data: {
        SAMPLE: {
            AWESOME: 'Create Awesome 🙌'
        },
        "Navbar": {
            "downloadApp": "تحميل التطبيق",
            "loginDashboard": "تسجيل الدخول إلى لوحة التحكم"
        },
        "Hero": {
            "welcomeMessage": "مرحباً بك في",
            "brandName": "IAM IN ",
            "empoweringEducation": "تمكين التعليم من خلال التكنولوجيا",
            "downloadApp": "قم بتحميل تطبيقنا"
        },
        "Home": {
            "totalInstructors": "إجمالي المدرسين",
            "InstructorsDesc": "مدرسينا المؤهلين",
            "totalStudents": "إجمالي الطلاب",
            "StudentsDesc": "انضم إلى آلاف المتعلمين",
            "totalCourses": "إجمالي الدورات",
            "CoursesDesc": "استكشف الدورات العديدة لدينا",
            "totalLives": "إجمالي الجلسات المباشرة",
            "LivesDesc": "تفاعل مع الخبراء لدينا في الوقت الفعلي"
        },
        "Features": {
            "rangeOfCourses": "مجموعة واسعة من الدورات",
            "rangeOfCoursesDesc": "الوصول إلى مجموعة متنوعة من الدورات عبر مختلف المواد والمستويات.",
            "expertInstructors": "مدرسين خبراء",
            "expertInstructorsDesc": "تعلم من خبراء الصناعة والمدرسين ذوي الخبرة.",
            "learnAnytime": "تعلم في أي وقت ومن أي مكان",
            "learnAnytimeDesc": "يتيح لك تطبيقنا التعلم أثناء التنقل وفي أي وقت."
        },
        "DownloadSection": {
            "title": "قم بتحميل تطبيقنا",
            "description": "متاح على iOS و Android",
            "appStore": "تنزيل من App Store",
            "googlePlay": "احصل عليه من Google Play"
        },
        "ContactUs": {
            "title": "اتصل بنا",
            "description": "نحن نتطلع لسماعك!",
            "nameLabel": "الاسم",
            "nameError": "الاسم مطلوب.",
            "emailLabel": "البريد الإلكتروني",
            "emailError": "البريد الإلكتروني صالح مطلوب.",
            "messageLabel": "الرسالة",
            "messageError": "الرسالة مطلوبة.",
            "sendMessage": "إرسال الرسالة"
        },
        "Footer": {
            "copyright": "جميع الحقوق محفوظة.",
            "termsConditions": "الشروط والأحكام",
            "privacyPolicy": "سياسة الخصوصية",
            "followUs": "تابعنا على"
        },
        "title1": "اكتشف آفاقًا جديدة",
        "subtitle1": "رحلتك إلى المعرفة تبدأ هنا",
        "title2": "أطلق العنان لإمكاناتك",
        "subtitle2": "افتح فرصًا لا حصر لها مع دوراتنا",
        "title3": "انضم إلى المجتمع",
        "subtitle3": "تواصل مع الخبراء والمتعلمين الآخرين"
    }
};
