export const locale = {
    lang: 'ar',
    data: {
        MENU: {
            DASHBOARD: {
                COLLAPSIBLE: 'لوحة القيادة'
            },
            USERS: {
                USER_MANAGEMENT: '--------- إدارة المستخدمين',
                ADMIN_MANAGEMENT: 'إدارة المسؤولين',
                ADMIN_LIST: 'قائمة المسؤولين',
                PERMISSIONS: 'إدارة الأذونات',
                ORGANIZATIONS: 'إدارة المنظمات',
                INSTRUCTORS: 'إدارة المدربين',
                STUDENTS: 'إدارة الطلاب'
            },
            COURSES: {
                COURSES_MANAGEMENT: '--------- إدارة الدورات',
                PORTAL: 'البوابة التعليمية',
                STAGE: 'المراحل التعليمية',
                SUBJECT: 'المواد',
                COURSES: 'الدورات'
            },
            APP: {
                MANAGEMENT: '--------- إدارة التطبيق',
                TRANSACTIONS: 'قائمة المعاملات',
                NOTIFICATIONS: 'قائمة الإشعارات',
                TERMS_AND_CONDITIONS: 'الشروط والأحكام',
                HELP_AND_SUPPORT: 'المساعدة والدعم',
                SETTINGS: 'الإعدادات'
            },
            OTHERS: {
                SUPPORT: 'الدعم والمساعدة'
            }
        }
    }
};
