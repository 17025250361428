<div class="root">
    <!-- vertical-layout -->
    <ng-container *ngIf="coreConfig.layout.type === 'vertical'">
        <vertical-layout></vertical-layout>
    </ng-container>
    <!-- / vertical-layout -->

    <!-- horizontal-layout -->
    <ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
        <horizontal-layout></horizontal-layout>
    </ng-container>
    <!-- / horizontal-layout -->

    <!-- theme customizer -->
    <core-sidebar *ngIf="coreConfig.layout.customizer" class="customizer d-none d-md-block" name="themeCustomizer">
        <a (click)="toggleSidebar('themeCustomizer')"
           class="customizer-toggle d-flex align-items-center justify-content-center">
            <span [class]="'spinner'" [data-feather]="'settings'"></span>
        </a>
        <core-theme-customizer></core-theme-customizer>
    </core-sidebar>
    <!-- / theme customizer -->

</div>
