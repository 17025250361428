import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from 'app/auth/service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    /**
     *
     * @param {Router} _router
     * @param {AuthenticationService} _authenticationService
     */
    constructor(private _router: Router, private _authenticationService: AuthenticationService) {
    }

    // canActivate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this._authenticationService.getToken()) {
            // check if route is restricted by role
            // if (route.data.roles && route.data.roles.indexOf(currentUser.roleId) === -1) {
            //   // role not authorised so redirect to not-authorized page
            //   this._router.navigate(['/pages/miscellaneous/not-authorized']);
            //   return false;
            // }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this._router.navigate(['/pages/auth/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
