<!-- Footer -->
<p class="d-flex justify-content-between align-items-center mb-0">
  <span class="d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://www.vocusdigital.com" target="_blank">VOCUS</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>