export const locale = {
    lang: 'en',
    data: {
        MENU: {
            DASHBOARD: {
                COLLAPSIBLE: 'Dashboard'
            },
            USERS: {
                USER_MANAGEMENT: 'Users Management',
                ADMIN_MANAGEMENT: 'Admins Management',
                ADMIN_LIST: 'Admin List',
                PERMISSIONS: 'Permissions Management',
                ORGANIZATIONS: 'Organization Management',
                INSTRUCTORS: 'Instructors Management',
                STUDENTS: 'Students Management'
            },
            COURSES: {
                COURSES_MANAGEMENT: 'Courses Management',
                PORTAL: 'Educational Portal',
                STAGE: 'Educational Stages',
                SUBJECT: 'Subjects',
                COURSES: 'Courses'
            },
            APP: {
                MANAGEMENT: 'App Management',
                TRANSACTIONS: 'Transaction List',
                NOTIFICATIONS: 'Notification List',
                TERMS_AND_CONDITIONS: 'Terms and Conditions',
                HELP_AND_SUPPORT: 'Help and Support',
                SETTINGS: 'Settings'
            },
            OTHERS: {
                SUPPORT: 'Support & Help'
            }
        }
    }
};
